.explanationSpeedDateWrapper {
	.speedDateContentWrapper {
		justify-content: start;
		gap: 20px;
		padding: 0 40px 60px;
		position: relative;

		.downArrow {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			position: fixed;
			bottom: calc(1rem + env(safe-area-inset-bottom));
			left: 1rem;
			z-index: 4;
			border-radius: 50%;
			padding: 1rem;
			background: #dff5f8;
			cursor: pointer;
			color: #2d5086;
		}
		h3 {
			margin: 10px 0 20px;
			font-size: 24px;
		}
		.explainImgBox {
			display: flex;
			flex-direction: column;
			gap: 30px;
			.explainImgItem {
				display: flex;
				gap: 20px;
				span {
					font-size: 15px;
					text-align: start;
				}
				img {
					width: 36px;
					height: fit-content;
				}
			}
		}
	}
}
