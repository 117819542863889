$fsecondary: brightness(0) saturate(100%) invert(52%) sepia(33%) saturate(7023%) hue-rotate(324deg)
	brightness(102%) contrast(93%);
.header {
	@media screen and (max-width: 780px) {
		height: 80px;
		min-height: 0;
		background: $jsmartGradientTriple;
		z-index: 1020;
		box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
		border-radius: 0;
		padding-inline-end: 0;
		background-color: $primary;
		background-image: $mobileHeaderGradient;
		padding-bottom: 1rem;
		padding-top: env(safe-area-inset-top);
		height: calc(80px + (env(safe-area-inset-top)));
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 4px;
			transform: translate(192%, -13%) scaleX(1);
			background-image: url("../../images/icons/birdsHeader.svg");
			background-size: 67%;
			background-position: center;
			background-repeat: no-repeat;
			width: 108px;
			height: 48%;
		}
		.innerHeader {
			.purchaseMinuy {
				display: none;
			}
			.siteLogo {
				display: none;
			}
			.nav {
				width: 100%;
				gap: 0;
				justify-content: space-between;
				padding-right: 0.5rem;
				padding-left: 0.5rem;
				margin-top: 1rem;
				.userTab {
					display: none;
				}
				li:nth-child(2) {
					padding-inline-start: 0;
				}
				> li:nth-child(2) {
					width: auto;
					display: flex;
				}
				> li:nth-child(3) {
					padding-inline-start: 0;
				}
				.nav-item {
					opacity: 1;
					.nav-link {
						padding-inline: 0;
						padding-block-end: 0;
						.wrapBadge {
							margin-right: 0;
							background-image: none;

							&.mobileOnly {
								display: flex;
							}
						}
						img {
							//filter: invert(32%) sepia(7%) saturate(21%) hue-rotate(349deg) brightness(99%) contrast(88%);
							height: 80%;
						}
						p {
							//display: none;
						}
					}
					&.active {
						img {
							//filter: invert(44%) sepia(24%) saturate(1563%) hue-rotate(184deg) brightness(87%) contrast(86%);
						}
					}
				}
			}
		}
	}

	.innerHeader {
		gap: 2rem;
		.userTab {
			.nav-item {
				a {
					button {
						img {
							filter: invert(1);
						}
					}
				}
				&:hover {
					a {
						button {
							img {
								filter: invert(1) !important;
							}
						}
					}
				}
			}
		}
	}
}

.topBar {
	Button.tagsBtn.newTag {
		@media screen and (max-width: 780px) {
			background-image: url("../../images/icons/filter.svg");
			background-repeat: no-repeat;
			background-position: 100%;
			filter: invert(27%) sepia(12%) saturate(3595%) hue-rotate(180deg) brightness(94%)
				contrast(85%);
		}
	}
}

.modal-dialog.bigSlideDialog.cardUserDialog {
	.modal-content {
		.modal-body {
			.galleryChat {
				.wrapBtnSend {
					.wrapTextDecoration {
						textarea.form-control {
							background-image: url("../../images/birdsOp.svg");
						}
						textarea {
							background: $mint url(../../images/birdsOp.svg);
						}
					}
				}
			}
		}
	}
}

.loginPagesWrapper {
	.loginPopup {
		.loginHolder {
			&.phoneEnter,
			&.verifyHolder,
			&.nameHolder {
				@media screen and (max-width: 780px) {
					padding: 1.8rem;
					padding-top: 7.5rem;
					background-image: url(../../images/birdsLogin.svg);
					background-size: 50%;
					background-repeat: no-repeat;
					background-position: 10% -60px;
					.imageForPage {
						display: none;
					}
				}
			}
		}
	}
}
.loginPagesWrapper.homeConnect {
	.homeContent {
		.wrapContentLP {
			.textForDesktop {
				h1 {
					.specialColor:before {
						left: 40px;
					}
				}
				.newTextDeskShlishFriends {
					margin-top: 2rem;

					h1,
					h2,
					p {
						display: inline;
						font-size: 3rem;
						font-weight: 700;
					}
				}
				.marriedMost {
					font-size: 8rem;
					font-weight: 800;
					line-height: 0.9;
					white-space: pre-line;
					margin-top: 0;
					margin-bottom: 0.5rem;
					@media screen and (max-width: 1200px) {
						font-size: 6rem;
					}
					.specialColor {
						color: #0bc1c7;
						display: inline;
						position: relative;
						padding-inline-start: 1.4rem;
						&:before {
							content: "";
							position: absolute;
							width: calc(100% + 25px);
							height: 1rem;
							background-image: url("../../images/underline.svg");
							bottom: -4px;
							background-repeat: no-repeat;
							left: 40px;
						}
					}
				}
			}
		}
		.wrapstats {
			.stats {
				h6 {
					line-height: 1;
				}
			}
			@media screen and (max-width: 1445px) {
				.pinkyWhite {
					width: 15%;
				}
			}
		}
	}
	.wrapMaintenance {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		.wrapGradient {
			width: 330px;
			height: 714px;
			background-image: $jsmartGradient;
			border-radius: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding-top: 7rem;
			gap: 10%;
			@media screen and (max-width: 780px) {
				width: 100%;
				height: 100%;
				justify-content: center;
				border-radius: 0;
				padding: 0 4% 4rem 4%;
			}
			.siteLogo {
				width: 70%;
				img {
					width: 100%;
				}
			}
			.messageMain {
				padding-inline: 1rem;
				display: inline-flex;
				flex-direction: column;
				gap: 3rem;
				justify-content: center;
				align-items: center;
				h3 {
					color: $white;
					font-weight: 500;
					text-align: center;
					@media screen and (max-width: 780px) {
						font-size: 1.8rem;
					}
				}
				h4 {
					color: $white;
					font-weight: 400;
					text-align: center;
					@media screen and (max-width: 780px) {
						font-size: 1.5rem;
					}
					&.desktop {
						display: none;
						@media screen and (max-width: 780px) {
							display: block;
						}
					}
				}
				.heartWhite {
					text-align: center;
					width: 100%;
					img {
						width: 2rem;
					}
				}
				a {
					color: #fff;
					text-align: center;
					font-size: 1.6rem;
					font-weight: 500;
					text-decoration: underline;
					border-bottom: 1px solid;
					display: inline-block;
					width: fit-content;
					@media screen and (max-width: 780px) {
						font-size: 1.8rem;
					}
					&.desktop {
						display: none;
						@media screen and (max-width: 780px) {
							display: block;
						}
					}
				}
			}
		}
		&.forcePage {
			.wrapGradient {
				padding: 0 4% 5.5rem 4%;
				.messageMain {
					gap: 4rem;
					h3.noGap {
						margin-bottom: -1.8rem;
					}
					a {
						background: #fff;
						color: $primary;
						border-radius: 5rem;
						padding: 0.3rem 2.5rem;
					}
				}
			}
		}
	}
}

.sideBarPanel {
	.chat-message-list {
		.chat-list li {
			.vip1 {
				@media screen and (max-width: 780px) {
					background-image: url("../../images/icons/chatVipMobile.svg");
				}
			}
		}
	}
}

.drop {
	color: $white;
	color: #fff;
	border: none;
	cursor: pointer;
	height: 55px;
	width: 26px;
	margin-top: -51px;
	pointer-events: all;
	position: absolute;
	padding-bottom: 1rem;
	transition: background-size ease-out 300ms;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	&:before {
		content: "";
		background-image: url("../../images/icons/drop.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		border: none;
		cursor: pointer;
		height: 38px;
		width: 26px;
		pointer-events: all;
		position: absolute;
		background-position: center 30%;
		padding-bottom: 0;
		transition: background-size ease-out 300ms;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: -1;
		top: 4px;
		filter: brightness(0) saturate(100%) invert(52%) sepia(33%) saturate(7023%) hue-rotate(324deg)
			brightness(102%) contrast(93%) !important;
	}
}

.buttonsSection2 {
	width: 70%;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin-top: 1rem;
	justify-content: center;
	align-items: center;
	.btn {
		width: 48%;
		border-radius: 5rem;
		background-color: $white;
		color: $primary;
		line-height: 0.9;
		padding: 0.5rem;
		border: 1px solid;
		@media (hover: hover) {
			&:hover {
				background: $primary;
				color: white;
			}
		}
		&[active="true"] {
			background-color: $primary;
			color: white;
		}
	}
}
.pillCount {
	background-color: red !important;
	position: absolute;

	left: auto;
	top: 5px;
	right: -5px;

	@media screen and (max-width: 1400px) {
		top: initial;
		right: initial;
		left: 50px;
	}
	@media screen and (max-width: 780px) {
		top: initial;
		right: initial;
		left: 30px;
	}
}

.blue-box-sidebar {
	padding: 10px 0 50px;
	flex-shrink: 0;
	border-radius: 10px;
	color: #2d5086;
	text-align: center;
	font-family: Rubik;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	img {
		margin-left: 8px;
		margin-top: -2px;
	}
}
