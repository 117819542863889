$linearColor: linear-gradient(140deg, #2d5086 5%, #0bc1c7 100%);

.LandingGiftCardPage {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #2d5086;
	margin-bottom: 200px;

	.headerLandingGiftCard {
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		align-items: center;

		background: $linearColor;
		padding: 90px 30px;
		color: #fff;
		font-size: 28px;
		min-height: 400px;
		width: 100%;
		margin-bottom: 130px;
		position: relative;
		.boldText {
			font-size: 50px;
			width: min-content;
			text-align: center;
			line-height: 1;
		}
		.giftCardImg {
			position: absolute;
			bottom: -100px;
			z-index: 0;
		}
		.logo {
			width: 110px;
			position: absolute;
			top: 20px;
			left: 50px;
		}
	}
	.contentLandingGiftCard {
		font-size: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #2d5086;
		padding: 0px 40px;
		gap: 20px;
		max-width: 500px;

		.purchaseGiftCard {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			max-width: 285px;
		}
		.howWork {
			color: #0bc1c7;
			font-weight: 700;
			font-size: 30px;
		}

		.imageTextRow {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			gap: 10px;
			font-size: 18px;
			font-weight: 300;
			.imgRow {
				width: 60px;
			}
		}
	}

	.purchGiftCardWrapper {
		position: fixed;
		bottom: calc(0px + env(safe-area-inset-bottom));
		padding: 20px 10px;
		background: #fff;
		width: 100%;
		min-width: 100vw;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 10px;
		box-shadow: #ccc 0px 0px 5px 0px;

		.goToPurchBtn {
			font-size: 24px;
			font-weight: 500;
			width: 70%;
			max-width: 400px;
			background: #f65368;
			border: none;
			color: #fff;
		}
		.termsGift {
			text-align: center;
			font-size: 15px;
			letter-spacing: -0.4px;
		}
	}
}

.headerPurchaseGiftCard {
	.giftCardImg {
		position: absolute;
		top: -15px;
		z-index: 0;
	}
	display: flex;
	flex-direction: column;
	gap: 0px;
	justify-content: center;
	align-items: center;
	background: #0bc1c7;
	padding: 200px 30px 90px;
	color: #fff;
	font-size: 28px;
	min-height: 400px;
	width: 100%;
	position: relative;
	text-align: center;
	.boldText {
		font-size: 48px;
		width: min-content;
		text-align: center;
		line-height: 1;
	}
}
.headerPurchaseGiftCardCardcom {
	padding: 170px 30px 30px;
	min-height: 300px;
	font-size: 20px;
	font-weight: 600;
}
.purchaseGiftCardPage {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #2d5086;
	margin-bottom: 150px;
	.cardcomBox {
		margin: 50px 0;
		iframe {
			border: 0;
			min-height: calc(100vh - 275px);
			min-width: 350px;
			position: absolute;
			right: 0;
			width: 100%;
			top: 275px;
		}
	}
}

.giftCardUserForm {
	padding: 45px;
	width: 100%;
	font-size: 16px;
	max-width: 500px;
	h2 {
		font-size: 24px;
		font-weight: 700;
		line-height: 1;
	}
	h4 {
		font-size: 18px;
		font-weight: 500;
		line-height: 1;
	}
	.wrapFormBox {
		margin: 30px 0 0;
		.textGiftCardComponent {
			.MuiInputBase-root {
				// border: 0;
				padding: 5px;
				.MuiInputBase-input {
					padding: 5px;
				}
			}

			width: 100%;
			border-radius: 5px;
			fieldset {
				border: 1px #dfdfdf solid !important;
				border-radius: 10px;
			}
			.Mui-focused {
				fieldset {
					border-radius: 10px;
					border: 1px #2d5086 solid !important;
				}
			}
		}
		.descriptionText {
			color: #939393;
			margin-top: 5px;
		}

		.MuiFormGroup-row {
			label {
				margin: 0;
				margin-left: 10px;
			}
		}
		.timeWrapper {
			display: flex;
			justify-content: start;
			align-items: center;
			gap: 10px;
			.timeGiftCardComponent {
				color: #2d5086;
				.MuiInputBase-root {
					padding: 5px;
					.MuiInputBase-input {
						padding: 5px;
					}
				}

				width: 50px;
				border-radius: 5px;
				fieldset {
					border: 1px #dfdfdf solid !important;
					border-radius: 10px;
				}
				.Mui-focused {
					fieldset {
						border-radius: 10px;
						border: 1px #2d5086 solid !important;
					}
				}
			}
			.wrapBlue {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				height: 15px;
				.blueCircle {
					display: block;
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background: #2d5086;
				}
			}
		}
	}
	.blessingBox {
		font-size: 18px;
		margin-top: 30px;
	}
}

.wrapGiftCardBtnForm {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;

	.nextBtn {
		margin-top: 30px;
		min-width: 70%;
		max-width: 300px;
		border-radius: 20px;
		font-size: 22px;
		padding: 0;
		span {
			position: relative;
			right: 10px;
		}
	}
	.submitBtn {
		border: #f65368 1px solid !important;
		background-color: #f65368 !important;
		color: #fff;
		min-width: 80%;
		max-width: 300px;
		font-weight: 600;
		border-radius: 20px;
		font-size: 22px;
		padding: 0;
		span {
			position: relative;
			right: 10px;
		}
	}
}

.progressGiftBtns {
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 10px;
	margin-top: 50px;
	.pageBox {
		width: fit-content;
		display: flex;
		flex-direction: column;
		gap: 10px;
		color: #2d5086;
		font-size: 22px;
		font-weight: 600;
		align-items: center;
		justify-content: center;

		span {
			text-align: center;
		}
		.number {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: linear-gradient(89deg, #2d5086 0%, #2d5086 30%, #2b83ab 85%, #0bc1c7 100%);
			color: #fff;
		}
	}
	.grayBox {
		width: 100%;
		background-color: #dfdfdf;
		height: 7px;
		border-radius: 20px;
		display: block;
		margin-top: 20px;
	}
}

.thanksGiftCardPage {
	width: 100vw;
	min-height: 100vh;
	padding-top: 150px;
	display: flex;
	justify-content: center;
	.giftCardImg {
		position: absolute;
		top: -15px;
		right: 0;
		left: 0;
		z-index: 0;
		max-height: 282px;
		margin: auto;
	}
	.contentThanks {
		max-width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 40px;
		color: #2d5086;
		width: 100%;
		height: 100%;
		min-height: calc(100vh - 150px);
		background-image: url("../../images/giftCard/portrait-woman-with-mobile.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		.blessingBox {
			font-size: 18px;
			margin-top: 30px;
			width: 100%;
		}
		.yourBlessing {
			font-size: 18px;
			margin-top: 30px;
			width: 100%;

			.blessing {
				margin-top: 15px;
				background-color: #fff;
				padding: 6px 12px;
				box-shadow: 0px 1px 15px 0px #ccc;
				border-radius: 20px;
				position: relative;
				white-space: pre-wrap;
				&::after {
					content: "";
					position: absolute;
					bottom: -3px;
					left: -4px;
					width: 15px;
					height: 20px;
					background-image: url("../../images/giftCard/Tail.png");
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
				}
			}
		}

		h2 {
			margin-top: 30px;
			display: flex;
			flex-direction: column;
			width: 100%;
			justify-content: center;
			align-items: center;
			font-size: 48px;
			line-height: 1;
		}
		.nextBtn {
			margin: 30px 0;
			min-width: 70%;
			max-width: 300px;
			border-radius: 30px;
			font-size: 24px;
			font-weight: 600;
			padding: 10px;
		}
	}
}

.receivedGiftCardPage {
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	color: #2d5086;

	.headerImage {
		background: #0bc1c7;
		position: relative;
		height: 200px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.giftCardImg {
			position: absolute;
			top: 50px;
		}
	}
	.receivedBox {
		margin: 80px 10px 0;
		margin-top: 80px;
		max-width: 300px;
		text-align: center;
		div {
			font-size: 15px;
		}
	}
	.blessing {
		min-width: 200px;
		max-width: 330px;
		margin-top: 30px;
		background-color: #fff;
		padding: 6px 12px;
		box-shadow: 0px 1px 15px 0px #ccc;
		border-radius: 20px;
		position: relative;
		white-space: pre-wrap;
		&::after {
			content: "";
			position: absolute;
			bottom: -3px;
			left: -4px;
			width: 15px;
			height: 20px;
			background-image: url("../../images/giftCard/Tail.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}
	@media print {
		.activeGiftBtn {
			display: none;
		}
	}
	.activeGiftBtn {
		margin: 40px 0 20px;
		width: 60%;
		max-width: 400px;
		border-radius: 30px;
		font-size: 24px;
		font-weight: 600;
		padding: 5px;
		background: #f65368 !important;
		border: none;
	}

	.activatedGift {
		max-width: 500px;
		.activeGiftBtn {
			width: 100%;
			background: #0bc1c7 !important;
			span {
				padding: 0 10px;
			}
		}
		h3 {
			font-weight: 700;
		}
	}
}

.modal-dialog.simpleDialog.receivedGiftCardModal {
	max-width: 500px;
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 768px) {
		max-width: 350px;
	}
	.user-chat-nav {
		position: absolute;
		z-index: 1;
		.nav-btn {
			color: #fff;
		}
	}
	.modal-content {
		height: calc(100vh - 80px);
		min-height: 0;
		border-radius: 20px;
		overflow: hidden;
		.modal-body {
			padding: 0;
			.receivedGiftCardPage {
				width: 100%;

				.headerImage {
					.giftCardImg {
						right: 0;
					}
				}
				.activeGiftBtn {
					span {
						color: #fff;
					}
				}
			}
		}
	}
}
