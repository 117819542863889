.modal-dialog.simpleDialog.chanelWrapper {
	min-width: 650px;
	.modal-content {
		background-color: #fff;
		.modal-header {
			border-radius: 20px 20px 0 0;
			justify-content: center;
			padding-top: calc(0.6rem + env(safe-area-inset-top)) !important;

			padding-bottom: 0.6rem !important;
			filter: drop-shadow(0px 2px 3px #e4e4e4);
			height: 70px;
			z-index: 1;
			background: #fff;
			width: 100%;
			.modal-title {
				padding: 0px;
				.chanelHeaderWrapper {
					display: flex;
					align-items: center;
					height: 70px;
					.name {
						display: flex;
						flex-direction: column;
						.headName {
						}
					}
				}
			}
		}
		.modal-body {
			background: #ffffff url(("../../images/birds.svg"));
			background-size: 20%;
			border-radius: 0 0 20px 20px;

			span {
				font-size: initial;
				text-align: start;
				max-width: 100%;
			}
			.conversation-list {
				min-width: 100%;

				.user-chat-content {
					.ctext-wrap {
						.ctext-wrap-content {
							color: #2d5086;
							padding: 12px 20px;
							img {
								max-height: 280px;
								margin: 15px 0;
								width: fit-content;
								object-fit: contain;
								border-radius: 10px;
							}
							.wrapOptions {
								display: flex;
								justify-content: space-around;
								gap: 10px;
								margin: 20px 5px;

								.options {
									min-width: 80px;
									border-radius: 25px;
									span {
										color: #f1f6f7;
										white-space: nowrap;
									}
								}
							}
							.matchmakerAns {
								margin: 20px 0;
							}
							div.commentSpan {
								span {
									white-space: pre-line;
								}
								img {
									max-width: 100%;
								}
								.moreDetailsPopup {
									margin: 0 0 10px;
									font-size: 1.1rem;
									cursor: pointer;
								}
							}
						}
					}
					.commentMsg {
						.ctext-wrap-content {
							width: 100%;
							border-radius: 15px;
							padding: 12px 10px;
							div.commentSpan {
								padding: 10px 20px;
								width: 100%;
								background: #2d518627;
								border-radius: 5px;
								text-align: center;
								margin-bottom: 10px;
								span {
									white-space: break-spaces;
								}
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 780px) {
		width: 100vw;
		min-width: 100%;
		margin: 0;
		border-radius: initial;
		font-size: initial;
		.modal-content {
			min-height: 100vh;
			border-radius: initial;
			.modal-header {
				position: fixed;
				top: calc(0 + env(safe-area-inset-top));
				justify-content: start;
			}
			.modal-body {
				border-radius: initial;
				box-shadow: 0px 3px 5px 1px #000;

				.conversation-list {
					.user-chat-content {
						.ctext-wrap {
							.ctext-wrap-content {
							}
						}
						.commentMsg {
							.ctext-wrap-content {
							}
							div.commentSpan {
							}
						}
					}
				}
			}
		}
	}
}
