.user-status.online {
	animation: pulseOnline 2s infinite alternate;
}

@keyframes pulseOnline {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.2);
		opacity: 0.9;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
