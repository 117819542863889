// .modal .modal-dialog.simpleDialog.questionsUser {
// 	.modal-content {
// 		overflow: auto;
// 		min-height: 500px;
// 		min-width: 500px;
// 		.modal-body {
// 			flex: 1;
// 			padding: 0;
// 			margin: 0;
// 			padding-top: calc(0.5rem + env(safe-area-inset-top));
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;

// 			.account-questions-box {
// 				min-height: 500px;
// 			}
// 		}
// 	}
// 	@media screen and (max-width: 780px) {
// 		width: 100vw;
// 		margin: 0;
// 		max-width: initial;
// 		.modal-content {
// 			width: 100vw;
// 			height: 100vh;
// 			max-width: initial;
// 			min-height: initial;
// 			min-width: initial;
// 			border-radius: 0;
// 		}
// 	}
// }

.account-questions-box {
	display: flex;
	justify-content: center;
	min-height: 100vh;
	width: 100vw;
	position: relative;

	.loading {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.arrowBlueQues {
			cursor: pointer;
			position: absolute;
			top: 45px;
			right: 20px;
			z-index: 10;
		}
	}

	.startEndPage {
		min-height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		overflow: hidden;
		flex: 1;
		position: relative;
		margin: 0;
		padding: 0;

		.contentStart {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: fit-content;
			margin: 20% 0;

			.textStart {
				margin: 0;
				padding: calc(3rem + env(safe-area-inset-top)) 0;
				font-size: 2.1rem;
				font-weight: 600;
				color: #2d5086;
				max-width: 90%;
				text-align: center;
				line-height: 1.3;
			}

			img {
				position: relative;
				right: -10px;
				max-width: 160px;
				object-fit: contain;
				height: min-content;
				padding: 0;
				margin: 0;
				width: 160px;
				height: 156px;
			}
		}
	}

	.boxBtn {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// box-shadow: 0 4px 16px 0 #00000040;
		padding: 20px 0;
		margin: 0;

		.start-button {
			margin: 0;
			padding: 10px 20px;
			min-width: 200px;
			background: #0bc1c7;
			color: white;
			border: none;
			border-radius: 15px;
			font-size: 16px;
			cursor: pointer;
			// align-self: end;
			font-weight: 600;

			&:hover {
				background: white;
				color: #0bc1c7;
			}
		}
	}

	.userQuickQuestion {
		width: 100vw;
		min-height: 100%;
		position: relative;
		padding: 50px 20px 20px;
		// justify-content: space-between;
		display: flex;
		flex-direction: column;
		margin: 0;

		.wrapUserQuickQuestion {
			display: flex;
			flex-direction: column;
			width: 100%;
			// height: 100%;
			margin-bottom: 20%;
		}

		.arrowBlueQues {
			cursor: pointer;
			position: absolute;
			top: 45px;
			right: 20px;
			z-index: 10;
		}
		.questionBox {
			h3 {
				margin: 0;
				padding: 2rem 0 1rem;
				font-size: 1.5rem;
				font-weight: 600;
				color: #2d5086;
				text-align: center;
			}
		}
		.gradientBox {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: start;
			gap: 3px;
			width: 100%;
			text-align: center;
			margin: 40px 0 0;
			position: relative;
			// transition: all 0.4s ease-in-out;

			.linearProgress {
				transform: rotateY(180deg);
				width: 100%;
				height: 10px;
				border-radius: 10px;
				background-color: #dfdfdf;
				.MuiLinearProgress-bar {
					border-radius: 10px;
					background: linear-gradient(90deg, #0bc1c7 40%, #2d5086);

					// &::after {
					// 	background: linear-gradient(90deg, #0bc1c7, #2d5086);
					// 	color: #fff;
					// 	padding: 5px;
					// 	border-radius: 20px;
					// 	font-size: 12px;
					// 	position: absolute;
					// }
				}
			}
			.grayProgress {
				background: #c2c2c2;
			}
			.percentVal {
				background: #2d5086;
				color: #fff;
				padding: 0;
				border-radius: 20px;
				font-size: 11px;
				position: absolute;
				border: 1px solid white;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 35px;
				width: 35px;
			}
		}
		.answerBox {
			margin-top: 30px;
			display: flex;
			width: 100%;
			flex-direction: column;
			gap: 10px;

			.userProfile {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				object-fit: fill;
			}

			.contentAns {
				background: #2d5086;
				border-radius: 20px 0 20px 20px;
				margin-right: 15px;
				padding: 30px 20px;

				display: flex;
				flex-direction: column;
				gap: 20px;

				.innerContentAns {
					background: #fff !important;
					color: #2d5086 !important;
					font-size: 16px;
					font-weight: 400;
					text-align: center;
					width: 100%;
					padding: 5px;
					border-radius: 20px;
					border: none;

					&:active,
					&.ansActive {
						background: #e3fcff !important;
						font-weight: 600;
					}
					&:disabled {
						opacity: 1;
					}
				}
			}
			.skipBackBox {
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.back {
					color: #939393;
				}
				.skip {
					color: #2d5086;
				}
			}
		}
		.goToAllAnswers {
			display: flex;
			justify-content: center;
			align-items: center;
			align-self: center;
			padding-top: 30px;

			span {
				font-weight: 600;
				color: #939393;
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.userQuickQuestion {
	.questionBox,
	.answerBox {
		opacity: 0;
		animation: fadeIn 0.5s ease-out forwards;
	}
}

.profileAccountQuestionWrapper {
	width: 100%;

	.wrapHeaderBtns {
		width: 100%;
		position: sticky;
		top: -1px;
		background: #fff;
		padding: 2px 0 10px;
		display: flex;
		align-items: center;
		z-index: 1;

		.moreIcon {
			position: absolute;
			left: -30px;
		}

		.accountQuestions {
			direction: rtl;
			width: 100%;
			border-radius: 20px;
			background: #e6ebf5;
			padding: 5px;
			.btn {
				border-radius: 20px !important;
				font-size: 12px;
				padding: 2px 30px;
				color: #2d5086;
				padding: 0rem 1rem;
				border-radius: 50rem !important;
				height: auto;
				line-height: 1.5;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 13px;
				font-weight: 500;

				&.active {
					color: #fff;
					border-radius: 50rem !important;
					height: auto;
					line-height: 1.5;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 13px;
					font-weight: 500;
				}
			}
		}
	}
	.wrapQuestionsAccount {
		display: flex;
		flex-direction: column;
		gap: 40px;
		width: 100%;
		padding: 30px 0 40px;
		.wrapInnerQuestionsAccount {
			display: flex;
			flex-direction: column;
			gap: 15px;
			.wrapQuestEdit {
				display: flex;
				gap: 5px;
				align-items: start;
				width: 100%;

				.checkBoxRemove {
					color: #2d5086;
					margin-top: 0;
					width: fit-content;
					padding: 0;
					display: inline-block;
				}

				.edit {
					position: relative;
					width: fit-content;
					height: 16px;
					max-height: 100%;
					cursor: pointer;
				}

				.form-label {
					margin: 0;
					font-size: 16px;
				}
			}
			.wrapBtns {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.regBtn {
					border-radius: 3rem;
					padding: 0rem 1.5rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					background: white;
					color: #2d5086;
					border-color: #2d5086;
					outline: 0;
					border-style: solid;
					border-width: 1px;
					text-align: center;
					line-height: 1.5;
					margin-inline-start: 0rem;
					margin-inline-end: 0.4rem;
					margin-bottom: 8px;

					&.selected {
						background: #2d5086;
						color: white;
					}
				}
			}
		}
	}

	// .bottomDelete {
	// 	z-index: 2;
	// 	width: 100%;
	// 	padding: 10px 0;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	background: rgba(0, 0, 0, 0.528);
	// 	position: absolute;
	// 	top: 0;

	// 	.btnDelete {
	// 		background: #fff;
	// 		color: red;
	// 		display: flex;
	// 		text-align: center;
	// 		justify-content: center;
	// 		align-content: center;
	// 		padding: 10px 20px;
	// 		border-radius: 10px;
	// 	}
	// }
}

.sideBarPanel {
	.userEdit {
		padding-bottom: 20px !important;
		.mainUserImage {
			width: 130px !important;
			height: 130px !important;
			border: none !important;

			.wrapPercentUser {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;

				.circulPercent {
					position: absolute;
					width: 130px;
					height: 130px;
					display: flex;
					justify-content: center;
					align-items: center;

					.MuiCircularProgress-circle {
						transition: stroke-dashoffset 0.01s ease-in-out; // Smooth transition for progress
					}
					span {
						width: 130px !important;
						height: 130px !important;
					}
					.editIcon {
						position: absolute;
						top: 3px;
						left: 5px;
						background-color: #fff;
						border-radius: 50%;
						width: 30px;
						height: 30px;
						z-index: 3;
						display: flex;
						justify-content: center;
						align-items: center;
						box-shadow: 0 4px 16px 0 #00000040;

						.edit {
							width: 16px;
							height: 16px;
							left: -10px;
							cursor: pointer;
						}
					}
				}

				a {
					width: 110px;
					height: 110px;
					z-index: 1;
					img {
						width: 110px;
						height: 110px;
						border-radius: 50%;
					}
				}

				.completionStack {
					position: absolute;
					bottom: -10px;
					background: linear-gradient(140deg, #0bc1c7 45%, #2d5086);
					color: white;
					border-radius: 20px;
					padding: 6px 12px;
					text-align: center;
					z-index: 2;

					.completionText {
						font-size: 12px;
						white-space: nowrap;
						color: white;
						line-height: 1;
						font-weight: 600;
					}
				}
			}
		}
		@media screen and (min-width: 780px) {
			.mainUserImage {
				border: none !important;

				.wrapPercentUser {
					a {
						width: 110px;
						height: 110px;
						img {
							width: 110px;
							height: 110px;
							border-radius: 50%;
						}
					}
					.circulPercent {
						width: 130px;
						height: 130px;
						span {
							width: 130px !important;
							height: 130px !important;
						}
					}
					.completionStack {
						.completionText {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

.userMatchQuestions {
	margin-top: 1.5rem;
	width: 100%;

	.wrapQuestions {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
		width: 100%;

		.showMoreQuestion {
			color: #0bc1c7;
			font-size: 16px;
			width: 100%;
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 10px;
			img {
				object-fit: contain;
				width: 13px;
				height: 13px;
			}
		}
	}

	.questionCard {
		background-color: white;
		border-radius: 8px;
		padding: 20px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		max-width: 300px;
		width: 100%;
		min-width: 100%;

		.questionText {
			font-size: 16px;
			margin-bottom: 10px;
			color: #939393;
			text-align: justify;
		}

		.answers {
			display: flex;
			flex-direction: column;

			.heartQuestion {
				width: 20px;
				position: relative;
				z-index: 0;
				top: -5px;
				right: 60px;
			}
			.answer {
				display: flex;
				align-items: center;
				gap: 20px;
				position: relative;
				margin-bottom: 10px;

				.avatar {
					align-self: flex-start;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					object-fit: cover;
					min-width: 40px;
				}

				.editSpan {
					display: flex;
					.edit {
						width: 20px;
						height: 100%;
						max-height: 100%;
						left: -10px;
						cursor: pointer;
					}
				}
				span {
					font-size: 14px;
					color: #939393;
					padding-inline-end: 20px;
				}
				.ansBtn {
					color: #0bc1c7;
					background: #e3fcff;
					border: #e3fcff;
					height: 40px;
					padding: 0 30px;
					border-radius: 25px;
					font-size: 14px;
				}
			}
		}
	}
}

.wrapQuestionDrawer {
	min-height: max-content;
	.questionText {
		font-size: 16px;
		margin-bottom: 10px;
		color: #939393;
		text-align: justify;
	}

	.wrapAnsBox {
		display: flex;
		flex-direction: column;
		padding-bottom: calc(env(safe-area-inset-bottom) + 25px);
		.wrapImgAns {
			display: flex;
			gap: 20px;
			.avatar {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				object-fit: cover;
				min-width: 40px;
			}

			.contentAns {
				width: 100%;
				background: #2d5086;
				border-radius: 20px 0 20px 20px;
				padding: 30px 20px;

				display: flex;
				flex-direction: column;
				gap: 20px;

				.innerContentAns {
					background: #fff !important;
					color: #2d5086 !important;
					font-size: 16px;
					font-weight: 400;
					text-align: center;
					width: 100%;
					padding: 5px;
					border-radius: 20px;
					border: none;

					&:active,
					&.ansActive {
						background: #e3fcff !important;
						font-weight: 600;
					}
					&:disabled {
						opacity: 1;
					}
				}
			}
		}
		.skipBackBox {
			display: block;
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.back {
				color: #939393;
			}
			.skip {
				color: #2d5086;
			}
		}
	}
}

@keyframes fadeInAi {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.sideBarPanel {
	.userEdit {
		position: relative;
		.BtnAiAboutMe {
			position: absolute;
			display: flex;
			align-items: center;
			gap: 2px;
			color: #2d5086;
			bottom: 0;
			padding: 0 0 10px;
			cursor: pointer;

			@media screen and (min-width: 780px) {
				align-self: center;
				position: relative;
				margin-top: 10px;
				padding: 0;
			}

			.stars {
				animation: fadeInAi 2s linear 2;
				color: #2d5086;
				position: relative;
				top: -5px;
			}
			.spanAi {
				border-radius: 25px;
				padding: 1px 5px;
				width: fit-content;
				font-size: 12px;
				font-weight: 300;
				background: #2d5086;
				color: #fff;
				font-weight: 600;
				&:hover {
				}
			}
		}
	}
}
