.emailDrawer {
	.simplebar-wrapper {
		overflow: scroll;
	}
	.MuiPaper-root {
		border-radius: 50px 50px 0 0;
		background: #2d5086;

		.simplebar-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.imgBox {
				background-image: url("../../images/emailDrawer.png");
				background-size: cover;
				background-position: center;
				width: 100%;
				height: 305px;

				&::after {
					content: "";
					position: absolute;
					top: 106px;
					left: 0;
					width: 100%;
					height: 210px;
					background: linear-gradient(rgba(255, 255, 255, 0) 25%, #2d5086 100%);
				}
			}

			.emailContent {
				width: 100%;
				// box-shadow: 0 0 100px 0 #2d5086;
				// background: linear-gradient(#fff 25%, #2d5086 50%);
				border-top-left-radius: 50px;
				border-top-right-radius: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 15px;
				padding-bottom: 50px;

				.emailTitle {
					color: #fff;
					font-size: 20px;
					line-height: 1.5;
					margin-top: 10px;
					max-width: 70%;
					font-family: "Rubik", sans-serif;
				}

				.emailPrompt {
					font-weight: bold;
					font-size: 28px;
					color: #fff;
					font-family: "Rubik", sans-serif;
				}

				.emailInput {
					width: 80%;
					background: #fff;
					border-radius: 10px;
					font-family: "Rubik", sans-serif;
					input {
						font-family: "Rubik", sans-serif;
						text-align: center;
					}
				}

				.confirmButton {
					font-family: "Rubik", sans-serif;
					min-width: 50%;
					background: #0bc1c7;
					color: white;
					font-size: 24px;
					font-weight: bold;
					width: fit-content;
					border-radius: 10px;
					margin-top: 10px;
					padding: 3px 10px;
					border-radius: 25px;
					&:hover {
						background: #0bc1c7;
					}
				}
			}
		}
	}
}
