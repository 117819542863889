$primary: #2d5086;
$secondary: #0bc1c7;
$shlishRed: #f65368;

.modal {
	.modal-dialog.simpleDialog.buyWithToken {
		min-width: 500px;
		.user-chat-nav {
			position: absolute;
			#user-profile-hide {
				top: calc(20px + env(safe-area-inset-top));
			}
		}
		@media screen and (max-width: 780px) {
			min-width: 100vw;
		}
		.modal-content {
			justify-content: initial;
			color: $primary;
			overflow: hidden;
			.colorHeader {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background: linear-gradient(300deg, #2d5086 0%, #2d5086 30%, #2b83ab 70%, #0bc1c7 100%);
				padding: 20px;
				padding-top: calc(20px + env(safe-area-inset-top));
				margin-bottom: 20px;
				img {
					height: 50px;
					object-fit: contain;
				}
			}
			.dealColorHeader {
				padding: 40px 20px;
				img {
					height: 70px;
				}
			}

			.modal-header {
				width: 100%;
				.modal-title {
					text-align: center;
					font-size: 36px;
					width: 100%;
					.coloredNum {
						background-color: #c6eff1;
						padding: 5px 8px;
						border-radius: 8px;
					}
				}
			}
			.modal-body {
				padding: 0 2.8rem;
				.textToken {
					font-size: 18px;
					font-weight: 600;
				}

				.formSelect {
					margin: 20px 0;
					min-width: 100px;
					#numOfPaymentsLabel {
						color: $primary;
					}
					.MuiSvgIcon-root {
						color: $primary;
					}
					.MuiInputBase-root,
					.Mui-focused {
						fieldset {
							border: 2px solid $secondary;
							border-radius: 320px;
						}
					}
				}
				.termsPurch {
					font-size: 16px;
				}
			}
			.modal-footer {
				width: 100%;

				.approvePurchesBtn {
					font-size: 24px;
					font-weight: 500;
					min-width: 70%;
					max-width: 300px;
					background: $shlishRed;
					border: none;
					color: #fff;
				}
				.purchesWithCredit {
					color: $primary;
					text-decoration: underline;
					font-size: 16px;
					&:hover {
						color: #fff;
						text-decoration: none;
					}
				}
			}

			@media screen and (max-width: 780px) {
				min-height: 100vh;
				border-radius: initial;
				.modal-header {
					.modal-title {
						font-size: 25px;
					}
				}
				.modal-body {
					font-size: 20px;
				}
				.modal-footer {
					.biggerBtn {
						font-size: 20px;
					}
				}
			}
		}
	}
}
